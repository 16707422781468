<template>
    <div id="cursubtenancy">
        <div class="logo">
         <img src="../assets/image/d.png" alt="">
         <div class="title">转借</div>
      </div>
      <div class="panel" >
          <p class="title">请确认转借信息: <span>&lt;&lt;{{bookinfo.title}}>></span> </p>
                <p>转借图书：{{bookinfo.title}}</p> 
                <p>转借人：{{bookinfo.name}}</p>
                <p>图书条码号：{{bookinfo.bookcode}}</p>
          <van-field
           style="margin:20px auto 0;"
              v-model="password"
              label-width="50px"
              clearable
               label="密码"
              type="password"
              placeholder="请输入您的密码"
            />
        </div>
        <div class="butbox">
              <van-button size="small" type="info" style="width:40%;margin:20px auto 0" @click="curUnderlease(0)" v-show="!finish">同意</van-button>
            <br/>
            <van-button size="small" type="danger" style="width:40%;margin-top:20px" @click="curUnderlease(1)" v-show="!finish">拒绝</van-button>
            <br/>
            <van-button size="small" type="info" style="width:40%;margin:20px auto 0"  v-show="finish">转借完成</van-button>
            </div>
            <!-- <van-popup v-model="show" get-container="body" >
             <van-field
              v-model="password"
              label-width="50px"
              clearable
               label="密码"
              type="password"
              placeholder="请输入您的密码"
            />
            </van-popup> -->
    </div>
</template>
<script>
import { curunderlease} from "@/api/goods.js";
export default {
    data(){
        return {
            bookinfo:{},
            show:false,
            title:'《儿童文学》',
            password:'',
            finish:false,
            successinfo:'',
            renewinfo:{
             title:"",
             result:"",
            callnum:"",
            renewTime:"",
            borrowTime:"",
            }
          
        }
    },
    created(){
        this.bookinfo=this.$route.query
        console.log(this.bookinfo);
    },
    methods:{
        curUnderlease(status){
            if(!this.bookinfo.title){
                 this.$toast.fail('转借信息错误');
                 return false
            }
            if(!this.password){
                 this.$toast.fail('请输入密码');
                 return false
            }
             curunderlease({
                keyID:this.bookinfo.keyID,
                bookName :this.bookinfo.title,
                readerId :this.bookinfo.readerId,
                readerName:this.bookinfo.name,
                applyReaderId:this.bookinfo.applyReaderId,
                barCode:this.bookinfo.bookcode ,
                password:this.password,
                status:status
              }).then(res=>{
                 console.log(res.data);
              this.$toast({
                    message:res.data.code==200?res.data.data:res.data.msg,
                    type:res.data.code==200?"success":'fail',
                    onClose:()=>{
                        if(res.data.code==200){
                         this.finish=true
                        }
                    }
                  })
              })
            
        }
    }
}
</script>
<style lang="scss" scoped>
 #cursubtenancy{
  width: 100%;
  height: 100%;
    .logo{
     margin-top:50px;
     width: 100%;
     height: 200px;
     .title{
       text-align: center;
       width: 100%;
       font-size: 20px;
     }
  }
  .panel{
      
       text-align: left;
       padding-left: 25px;
       box-sizing: border-box;
      .title{
          font-size: 20px;
          color:rgba($color: #28a8e493, $alpha: 1.0);
           span{
               font-size: 16px;
               color:rgba($color: #0d6d1a93, $alpha: 1.0)
           }
      }
      p{
          font-size: 16px;
      }
      .butbox{
          text-align: center;
      }
      .info{
          font-size: 14px;
              text-align: left;
              margin-left: 30px;
               color:rgba($color: #0d6d1a93, $alpha: 1.0)
          
      }
  }
}
</style>